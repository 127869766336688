import { createRouter, createWebHistory } from 'vue-router';
import PageNotFound  from '../views/PageNotFound.vue'
// import { mapGetters } from 'vuex'

import App from '../App.vue';
import { store } from '@/_store';

const routes = [
    {
        path: '/',
        name: 'app',
        component: App,
        children: [
            {
                path: '',
                name: 'home',
                component: () => import('../views/HomePage.vue'),
                meta: {    requiresAuth: false, verify: false   },
                metaTags: [
                    {
                      name: 'description',
                      content: 'Inscrivez vous et profitez des nombreuses places dans les formations organisées par pole emploi et la région Grand Est.'
                    },
                    {
                      property: 'og:description',
                      content: 'Stages & Emplois.'
                    }
                ]
              },
            {
                path: '/inscription',
                name: 'inscription',
                component: () => import('../views/MlearningStepper.vue'),
                meta: {    requiresAuth: false, verify: true   },
            },        
            {
                path: '/register',
                name: 'register',
                component: () => import('../views/InscriptionStepper.vue'),
                meta: {    requiresAuth: false, verify: true   },
            },        
            {
                path: '/pole-emploi',
                name: 'poleemploi',
                component: () => import('../views/PoleEmpStepper.vue'),
                meta: {    requiresAuth: false, verify: true   },
            },  
            {
                path: '/about',
                name: 'about',
                component: () => import('../components/about/IndexMain.vue'),
                meta: {    requiresAuth: false, verify: true   },
            },        
            {
                path: '/validation',
                name: 'validation',
                component: () => import('../views/EmailValidation.vue'),
                meta: {    requiresAuth: true, verify: false   },
            }
            ,
            {
                path: '/deja-inscrit',
                name: 'deja-inscrit',
                component: () => import('../views/EmailErreur.vue'),
                meta: {    requiresAuth: false, verify: false   },
            }
            ,
            {
                path: "/:catchAll(.*)",
                component: PageNotFound,
                meta: {
                  title: "Not Found || 1 clic pour 1 déclic, accueil-trouvez votre formation",
                  requiresAuth: false, verify: false 
                },
            },
        ]
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

router.beforeEach((to, from, next) => {

    const loggedIn = localStorage.getItem('user');
    
    // const test   =    store.getters['appstate/getStatus'];
    // const verify =  store.getters['interns/getVerify'];

    if (to.meta.requiresAuth && !loggedIn) {
        next('/register');
    }
    
     
    if(to.meta.verify && Object.values(store.state.interns.verify).includes(to.params.id)){
        next('/register');
    }
  
    next();
  })

export default router;