import { userService } from '../_services';
import  router from '../_helpers/router';

const user = JSON.parse(localStorage.getItem('user'));
const state = user
    ? { status: { loggedIn: true }, user }
    : { status: {}, user: null };

const actions = {
    login({ dispatch, commit }, { username, password }) {
        commit('loginRequest', { username });
    
        userService.login(username, password)
            .then(
                user => {
                    commit('loginSuccess', user);
                    router.push('/');
                },
                error => {
                    commit('loginFailure', error);
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    logout({ commit }) {
        userService.logout();
        commit('logout');
    },
    // a enlever uniquement pour formulaires validation test
    //
    fakerCode({dispatch, commit}, stagiaire ){
        commit('registerRequest', user);
        commit('registerSuccess', user);
        router.push('/home');
            setTimeout(() => {
             // display success message after route change completes
            dispatch('alert/success', 'Enregistrement de ' + stagiaire.name + ' ' + stagiaire.email + stagiaire.message, { root: true });
        })
    },
    // a enlever uniquement pour formulaires test
    //
    faker({dispatch, commit}, stagiaire ){
        commit('registerRequest', user);
        commit('registerSuccess', user);
        router.push('/codevalidation');
            setTimeout(() => {
             // display success message after route change completes
            dispatch('alert/success', 'Enregistrement de ' + stagiaire.name + ' ' + stagiaire.email + stagiaire.message, { root: true });
        })
    },
    register({ dispatch, commit }, user) {
        commit('registerRequest', user);
    
        userService.register(user)
            .then(
                user => {
                    commit('registerSuccess', user);
                    router.push('/login');
                    setTimeout(() => {
                        // display success message after route change completes
                        dispatch('alert/success', 'Registration successful', { root: true });
                    })
                },
                error => {
                    commit('registerFailure', error);
                    dispatch('alert/error', error, { root: true });
                }
            );
    }
};

const mutations = {
    loginRequest(state, user) {
        state.status = { loggingIn: true };
        state.user = user;
    },
    loginSuccess(state, user) {
        state.status = { loggedIn: true };
        state.user = user;
    },
    loginFailure(state) {
        state.status = {};
        state.user = null;
    },
    logout(state) {
        state.status = {};
        state.user = null;
    },
    registerRequest(state) {
        state.status = { registering: true };
    },
    registerSuccess(state) {
        state.status = {};
    },
    registerFailure(state) {
        state.status = {};
    }
};

export const account = {
    namespaced: true,
    state,
    actions,
    mutations
};