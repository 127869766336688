export function authHeader() {
    // return authorization header with token
    let user = JSON.parse(localStorage.getItem('user')); 
    if (user && user.access_token) {
        return { 
            'Authorization': 'Bearer ' + user.access_token,
            'x-api-key': process.env.VUE_APP_API_KEY,
            'Content-Type': 'application/json;charset=utf-8' 
        };
    } else {
        return { 
            'x-api-key': process.env.VUE_APP_API_KEY , 
            'Content-Type': 'application/json;charset=utf-8' 
        };
    }
}