import { mlService } from '@/_services/v1.ml.service';
import { crypt, decrypt } from '@/_helpers/cryptuser';
import router from '../_helpers/router';


const User = JSON.parse(localStorage.getItem('User'));
// cryptage et décryptage pour appli officielle 
const tUser = localStorage.getItem('cUser') 
const dUser = tUser ? decrypt(tUser): null
// fin
// console.log('DUSER', dUser, User)
const state = User
    ? { all: {  user: dUser , cUser: User } }
    : { all: {  user: null } };

const getters = {   
    savedUser( state ) {
      return state.all.user;
    },    
}

const actions = {     
    async saveData({state}) {  
        await mlService.saveTest(state.all.user);            
    },        
    saveGems({commit}, payload) {
        commit('storeGems', payload)        
        commit('storeLocaluser');    
    },
    saveUser({commit}, payload) {
        commit('storeUser', payload)             
        commit('storeLocaluser');     
    },
    saveSecteurs({commit}, payload) {
        commit('storeSecteurs', payload)            
        commit('storeLocaluser');    
    },
    savePictures({commit}, payload) {
        commit('storePictures', payload)            
        commit('storeLocaluser');    
    },

    resetData({ commit }) {
        localStorage.removeItem('User');
        localStorage.removeItem('cUser');
        commit('resetUser');
        // router.push('/');        
    },
    start({ dispatch}) {
        dispatch('resetData')        
        router.push('/inscription');        
    },
    startRegister({ dispatch}) {
        dispatch('resetData')        
        router.push('/register');        
    }
};

const mutations = {   
    storeLocaluser(state) {                                   
        let cr = crypt((state.all.user));           
            // let decrypted = CryptoJS.AES.decrypt(encrypted, key);                  
        localStorage.setItem('User', JSON.stringify(state.all.user));
        localStorage.setItem('cUser', cr)
    },
    //               
    resetUser(state){
        // state.all = { user: {}, gems:{}, secteurs: {}, pictures:{} };
        state.all = { user: {}, cUser: {} };
    },    
    //    
    storeUser(state, user){
        //Save all data in the store        
        state.all =  {...state.all, user : user }              
    },
    //
    storeGems(state, gems){
        let tGems = gems.map(prod => { return { 'gem_id': prod.option_id, value: prod.value }})                      
        //Save all data in the store             
        state.all.user =  {...state.all.user, gems : tGems }         
    },
    //    
    storeSecteurs(state, secteurs){
        let tSecteurs = secteurs.map(prod => { return { 'secteur_id': prod.option_id, value: prod.value }})            
        //Save all data in the store                
        state.all.user =  { ...state.all.user, secteurs : tSecteurs }              
    },
    //
    storePictures(state, pictures){

        let tVisuel = pictures.map(prod => { return { 'visuel_id': prod.option_id, value: prod.value }})            
        //Save all data in the store                
        state.all.user =  { ...state.all.user, pictures : tVisuel }              
    }
};

export const ml = {
    namespaced: true,
    state,
    actions,  
    getters,
    mutations
};